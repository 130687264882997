.privacy-modal {
  .modal-body {
    padding: 2.5rem 1.5rem;

    h6 {
      color: $abBlue;
      font-weight: 600;
    }
  }

  .title {
    font-size: 1.25rem;
    color: #666;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  ol, ul,
  .text {
    color: #666;
    font-size: .75rem;

    li {
      margin-bottom: .25rem;
    }
  }

  ul, ol {
    &.letter-list {
      list-style: lower-latin;
    }

    &.disc-list {
      list-style: disc;
    }
  }
}
