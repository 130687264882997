@import url('https://fonts.googleapis.com/css?family=Assistant:300,400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap');

//General modules
@import 'bootstrap/scss/bootstrap.scss';
@import 'modules/_typography';
@import 'modules/_variables';

//Components modules
@import 'modules/base';
@import 'modules/buttons';
@import 'modules/forms';
@import 'modules/table';
@import 'modules/pagination';
// @import 'modules/suggestions';
@import 'modules/loader';
@import 'modules/nav';
@import 'modules/pages';
@import 'modules/landing';
@import 'modules/cadastral';
@import 'modules/otp';
@import 'modules/legend';
@import 'modules/privacy';
@import 'modules/chat';
@import 'modules/status-history';
@import 'modules/bank-account';
