.navbar {
  padding: 0;
  background-color: $navBackground;

  .navbar-toggler {
    border: none;
    margin-right: 1rem;
    outline: none;
  }

  .navbar-brand {
    padding: 1rem 0;
    width: 160px;
    height: 80px;

    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }

  .collapse {
    @include media-breakpoint-up(md) {
      padding: 0 1rem;
    }
  }

  .navbar-nav {
    @include media-breakpoint-down(sm) {
      padding: .25rem 0;
    }

    .username {
      margin-left: .15rem;
    }

    .nav-item {
      padding: .5rem .75rem;

      @include media-breakpoint-down(md) {
        padding: .5rem .4rem;
      }

      @include media-breakpoint-down(sm) {
        padding: .5rem 0;
      }

      .btn-link,
      .nav-link {
        padding: 0;
        font-size: .85rem;
        font-weight: 600;
        position: relative;
        color: $defaultLink;

        transition: color .2s ease-out;

        @include media-breakpoint-down(md) {
          font-size: .75rem;
        }

        @include media-breakpoint-down(sm) {
          font-size: .85rem;
          padding-left: 10px;
        }

        &:hover {
          color: $hoverLink;

          &:before {
            transform: scaleX(1);
          }
        }
        &.active {
          color: $activeLink;

          &:before {
            transform: scaleX(1);
          }
        }

        &:before {
          content: "";
          position: absolute;
          margin-top: 3px;
          top: 100%;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: $abYellow;

          transition: transform .3s ease-out;
          transform-origin: 0 50%;
          transform: scaleX(0);

          @include media-breakpoint-down(sm) {
            top: 0;
            width: 5px;
            height: 100%;
          }
        }

        > svg {
          margin-right: .15rem;

          @include media-breakpoint-down(md) {
            display: none;
          }

          @include media-breakpoint-down(sm) {
            display: inline-block;
          }
        }

        &.dropdown-toggle:after {
          vertical-align: middle;
        }
      }
    }

    .dropdown-menu {
      left: 50%;
      top: calc(100% + 5px);
      transform: translateX(-50%);
      padding: .5rem 0;
      width: max-content;
      border-left: 5px solid $abYellow;
      border-radius: 0 .25rem .25rem 0;
      box-shadow: 0px 3px 10px #00000029;

      @include media-breakpoint-down(sm) {
        width: 100%;
        position: relative;
        border-radius: 0;
        background-color: #f1f1f1;
        border: none;
        max-height: 50vh;
        overflow: auto;
        box-shadow: none;

        &:before {
          display: none;
        }
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        border-left: 5px solid transparent;
        border-top: 5px solid transparent;
        z-index: 1;
        transform: translateX(-50%);
      }

      .menu-items {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-down(sm) {
          display: block;
        }
      }

      .dropdown-group {
        padding: .5rem .5rem;
        flex-basis: 25%;
        margin-bottom: .75rem;
        border-right: 1px solid $white;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
          padding: .5rem .5rem;
        }

        &:last-child,
        &:nth-child(4n) {
          border-right: none;
        }
      }

      .dropdown-header {
        padding: .35rem .5rem;
        font-size: .8rem;
        font-weight: 700;
        color: $white;
        background-color: $abBlue;
        border-radius: 2px;
        margin-bottom: .5rem;

        svg {
          margin-right: .4rem;
          font-size: .8rem;
        }
      }

      .dropdown-item {
        padding: 0;

        &:hover,
        &:active {
          background: rgb(233,236,239);
          background: linear-gradient(90deg, rgba(233,236,239,1) 0%, rgba(255,255,255,1) 100%);
        }

        .btn-link,
        .nav-link {
          text-align: left;
          font-size: .8rem;
          padding: .15rem .7rem;
          color: #666;
          text-decoration: none;
          display: block;

          @include media-breakpoint-down(sm) {
            padding: .75rem 1rem;
          }

          &:before {
            top: 0;
            width: 5px;
            height: 100%;
            margin-top: 0;
          }

          &.active {
            background: rgb(233,236,239);
            background: linear-gradient(90deg, rgba(233,236,239,1) 0%, rgba(255,255,255,1) 100%);
            
            &:before {
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }
}
