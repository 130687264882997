.legend {
  padding-bottom: .25rem;
  margin-bottom: 2.5rem;
  text-align: right;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-left: 1rem;
      font-size: .75rem;
      color: $abBlue;

      &.title {
        font-weight: 600;
        font-size: .85rem;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .fa-circle-xmark {
        color: red;
      }

      .fa-door-open {
        color: $doorOpen;
      }
      .fa-signature {
        color: $signature;
      }
      .fa-gears {
        color: $cogs;
      }
      .fa-clipboard-check {
        color: $clipBoardCheck;
      }
      .fa-circle-check {
        color: $checkCirlce;
      }
      .fa-hand {
        color: $handPaper;
      }
      .fa-circle-half-stroke {
        color: $abYellow;
      }
      .fa-arrows-rotate {
        color: $abBlue;
      }
    }
  }
}
