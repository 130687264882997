.page-container {
  position: relative;

  .page {
    position: absolute;
    padding-top: 80px;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: $pageWhite;

    transition: opacity .3s ease-out, visibility .3s ease-out;

    &.fade-enter {
      opacity: 0;
      visibility: hidden;
      transition-delay: .15s;

      &.fade-enter-active {
        opacity: 1;
        visibility: visible;
      }
    }

    &.fade-exit {
      opacity: 1;
      visibility: visible;

      transition-duration: .15s;

      &.fade-exit-active {
        opacity: 0;
        visibility: hidden;
      }
    }

    .jumbotron {
      background-color: $white;
      margin-bottom: 0;
    }
  }
}

.page-header {
  padding: 1rem;
  background-color: $abBlue;
  color: $white;

  .page-title {
    font-size: 1rem;
    margin: 0;
  }

  .lead {
    font-size: .85rem;
    margin: 0;

    strong {
      font-weight: 700;
    }
  }

  .other-links-container {
    display: flex;
    justify-content: flex-end;
    
    @include media-breakpoint-down(sm) {
      justify-content: flex-start;
      margin-top: 1rem;
    }
  }

  .other-links {
    padding: 0.375rem 0.75rem;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: .25rem;
    font-size: .85rem;
    font-weight: 700;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

    > svg {
      margin-right: .25rem;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 217, 218, 0.5);
    }

    &:active,
    &:hover {
      color: #337ab7;
      background-color: #fff;
    }
  }
}

.page-404 {
  .title {
    text-align: center;
    margin: 1rem 0;
  }
}
