$white: #f1f1f1;
$grey: #ddd;
$darkGrey: #666;
$red: #dc3545;
$abYellow: #ffc525;
$abYellowHover: #bd921b;
$abBlue: #337ab7;
$tableContent: #343a40;

$navBackground: #0065ab;
$pageHederBkg: #f6f6f6;

//Status icons
$doorOpen: #af24e8;
$signature: #ff8300;
$cogs: #1abed9;
$clipBoardCheck: #337ab7;
$checkCirlce: #4db733;
$handPaper: #e12929;

//Page
$pageWhite: #fff;

//Form input
$inputBorder: #c6d7e6;

//Link
$defaultLink: #81c3f1;
$activeLink: #f1f1f1;
$hoverLink: #ddd;


//Lists
$statusColor: #666;
