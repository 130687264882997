.landing {
  main {
    // background-color: $white;
    padding: 2.5rem 0;
  }
}

.card {
  margin-bottom: 1.5rem;
  border-radius: .75rem;
  box-shadow: 0 0 15px -7px #c5c5c5;
  overflow: hidden;

  .card-header {
    font-weight: 700;
    font-size: .85rem;
    text-transform: uppercase;
    background-color: $abBlue;
    color: #fff;
    border-radius: unset;

    &.inverted {
      color: $abBlue;
      background-color: #fff;
    }
  }
  .card-body {
    .card-text {
      font-size: .9rem;
    }

    button {
      font-size: 1rem;
    }
  }
}

.all-contracts-banner {
  display: flex;
  background-color: #E9F0F7;
  border-color: #E9F0F7;
  align-items: center;
  margin-bottom: 2rem;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }

  .text-container {
    flex-grow: 1;
    margin-right: 1rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }

    .title {
      font-size: 14px;
      font-weight: bold;
      padding-right: 1rem;
      border-right: 1px solid #999;
      color: $abBlue;
      text-transform: uppercase;
    }

    .desc {
      padding-left: 1rem;
      font-size: 14px;
      color: $black;
    }
  }

  .btn {
    color: $abBlue;
    border-color: $abBlue;

    @include media-breakpoint-down(sm) {
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      color: $white;
      background-color: $abBlue;
    }
  }
}

.main-title {
  font-size: 34px;
  color: $abBlue;
  font-weight: bold;
  margin-bottom: 0;
}

.contracts-shortcuts {
  text-align: right;
  margin: 1.5rem auto;

  .title {
    font-size: 14px;
    font-weight: bold;
  }

  .links-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
      margin-left: .5rem;
      color: $abBlue;
      border-color: $abBlue;

      @include media-breakpoint-down(xs) {
        padding-left: .5rem;
        padding-right: .5rem;
        margin-left: .15rem;
      }

      &:hover {
        color: $white;
        background-color: $abBlue;
      }
    }
  }
}

.contracts-section {
  margin-bottom: 5rem;

  .section-title {
    font-size: 26px;
    font-weight: bold;
    color: $abBlue;
  }

  .section-subtitle {
    font-size: 18px;
    font-weight: 600;
  }

  .contracts-container {
    margin-top: 1.5rem;
    padding-left: 1.25rem;
    padding-top: 1rem;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: .25rem;
      left: 0;
      top: 0;
      background-color: $abYellow;
      height: 100%;
    }

    .entity-row {
      display: flex;
      align-items: flex-start;
      padding: 1rem;
      border: none;
      box-shadow: 0px 3px 10px #00000029;
      margin-bottom: 1.5rem;
      border-radius: 5px;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }

      .text-container {
        font-size: 18px;
        color: $abBlue;
        font-weight: bold;
        text-transform: uppercase;
        flex-grow: 1;
        line-height: 1.2;

        sup {
          font-size: 10px;
          margin-left: .5rem;
          font-weight: bold;
          padding: .1rem .5rem;
          background-color: $abYellow;
          border-radius: 10px;
          vertical-align: super;
        }

        svg {
          font-size: 1rem;
        }

        .more-info {
          margin-top: .25rem;

          @include media-breakpoint-down(sm) {
            margin-top: 0;
          }

          button {
            padding: 0;
            font-size: 14px;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }

      .actions-container {
        display: flex;

        @include media-breakpoint-down(sm) {
          margin-top: 1.5rem;
          margin-left: auto;
        }

        .btn {
          margin-left: 1rem;

          color: $white;
          background-color: $abBlue;
          border-color: $abBlue;

          &:hover {
            color: $abBlue;
            background-color: transparent;
          }

          &.outline {
            color: $abBlue;
            border-color: $abBlue;
            background-color: transparent;

            &:hover {
              color: $white;
              background-color: $abBlue;
            }
          }
        }
      }
    }
  }
}

.entity-info-modal {
  .icon {
    text-align: center;
    font-size: 30px;
    color: $abYellow;
  }

  .title {
    margin: 1.5rem auto;
    color: $abBlue;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    text-align: center;

    svg {
      margin-right: .5rem;
    }
  }

  .desc {
    font-size: 16px;
    color: #222529;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
  }

  .modal-footer {
    justify-content: center;

    button {
      color: $abBlue;
      border-color: $abBlue;
      font-size: 14px;
      font-weight: bold;

      &:hover {
        color: $white;
        background-color: $abBlue;
      }
    }
  }
}