.notes {
  min-height: 150px;

  .list-group-item {
    font-size: .85rem;
    padding: .5rem;

    p {
      margin-bottom: .5rem;
      font-size: .75rem;
    }

    blockquote {
      margin-bottom: 0;
      font-style: italic;
    }

    strong {
      color: $abBlue;
    }
  }

  .form-group {
    margin-top: 1rem;

    label {
      font-size: 1rem;
      font-weight: 600;
      color: $abBlue;
    }
  }
}
