.status-history {
  display: flex;
  align-items: center;

  .icon {
    font-size: 2rem;
    color: $abBlue;
  }

  .status-icon {
    color: $abBlue;
  }

  strong {
    font-size: .85rem;
  }
}
