body {
  // background-color: $white;
  &.modal-open {
    padding-right: 0 !important;
  }
}

#maintenance-alert {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
  margin: 1.5rem auto;
  z-index: 999;
  font-family: 'Montserrat';

  @include media-breakpoint-down(sm) {
    margin: 1.5rem;
  }

  .content {
    display: flex;
    align-items: flex-start;
    
    @include media-breakpoint-down(sm) {
      > svg {
        display: none;
      }
    }
  }
}