.penso-in-verde-icon {
  fill: #71b141;
  margin-right: .5rem;
}

.text-muted {
  font-size: 70%;

  .penso-in-verde-icon {
    width: 16px;
  }
}

.form-container {
  margin: 2.5rem auto;
}

.form-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  background-color: rgba(240, 240, 240, .7);
  z-index: 15;

  > .label {
    margin-top: 1rem;
    font-size: .85rem;
    color: $abBlue;
    font-weight: 600;
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

form {
  .col-form-label {
    font-size: .85rem;
    font-weight: 700;
    color: #666;
  }

  .form-group,
  .form-check {
    .invalid-feedback,
    .valid-feedback {
      font-size: .8rem;
      font-weight: 400;
    }
  }

  .form-control {
    font-size: .85rem;

    &::placeholder {
      color: $grey;
      font-weight: 400;
      font-size: .85rem;
    }

    &.is-valid,
    &.is-invalid {
      background-position: calc(100% - 0.8875rem) center;
    }
  }

  .form-error {
    color: $red;
    font-weight: 600;
    margin: .5rem 0;
  }

  button[type="submit"] {
    background-color: $abBlue;
    color: $white;
  }

  .checkbox-label {
    font-size: .75rem;
  }

  //Two cols form
  &.filters-form,
  &.two-cols-form {
    .form-group {
      margin-bottom: .75rem;
    }
    
    label {
      font-weight: 600;
      font-size: .8rem;
      color: $abBlue;
      margin-bottom: .15rem;
      min-width: 120px;
    }

    .form-control {
      border-color: $inputBorder;
    }
  }
}

.filters-submit {
  display: flex;

  > button {
    flex-grow: 1;
  }
}

//Subscription form nav
.subscription-nav {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .nav-item {
    .nav-link {
      color: $abBlue;
      cursor: pointer;
      padding: .5rem;

      @include media-breakpoint-down(sm) {
        font-size: .75rem;
      }

      &.disabled {
        color: $grey;
        cursor: default;
      }

      &.done {
        color: $defaultLink;
      }
    }
  }
}

.grouped {
  margin-bottom: 1.5rem;
  border-radius: .75rem;
}

.grouped-header {
  background-color: $pageHederBkg;
  border-radius: .75rem .75rem 0 0;
  border: 1px solid $grey;
  color: #666;
  padding: .5rem .75rem;
}
.grouped-body {
  padding: .5rem .75rem;
  border: 1px solid $grey;
  border-top: none;
  border-radius: 0 0 .75rem .75rem;

  &.privacy {
    p {
      font-weight: 600;
      font-size: .8rem;
      color: #337ab7;
      margin-bottom: 0.15rem;
      min-width: 120px;
    }
  }
}
.grouped-input {
  padding: 1rem;
}

.dropzone {
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  border: 2px dashed $grey;
  margin-bottom: .5rem;

  > p {
    text-align: center;
    margin: 0;
    color: #666;
  }
}
.dropzone-listgroup {
  margin: 1.5rem 0 0;  
  background-color: $white;
  padding: 1.25rem;
  border: 1px solid $grey;
  border-radius: 0.5rem;

  .attachment-row {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    background-color: #fff;
    padding: 1.25rem; 
    border-radius: 0.75rem;
    box-shadow: 0 0 15px -7px #c5c5c5;

    .attachment-datetime {
      font-size: .8rem;
      margin: .25rem 0 0;
      padding: 0 .25rem;
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .btn {
      width: 34px;
    }

    > * {
      margin: .15rem auto;
    }
  }

  .list-title {
    font-size: 1rem;
    color: $abBlue;
  }

  .form-group {
    margin: 0 !important;
  }
}

.form-dashboard {
  .title {
    font-size: 1rem;
    color: $abBlue;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .list {

    .list-group-item {
      outline: none;
      font-weight: 600;

      .context-menu {
        display: flex;

        button {
          margin: 0 .25rem;
        }
      }
    }
  }

  .control-buttons {
    display: flex;
    justify-content: flex-end;
  }
}

.utility-modal,
.signature-type-modal,
.contract-finalization-modal,
.status-modal,
.form-modal {
  .modal-header {
    background-color: $abBlue;

    .modal-title {
      color: $white;
    }

    .close {
      text-shadow: none;
      color: $white;
      opacity: 1;
    }
  }
}

.utility-modal {
  .modal-body {
    font-size: .75rem;
    color: $darkGrey;

    h6 {
      color: $abBlue;
    }
  }
}


.signature-type-modal {
  .list-group-item {
    display: flex;
    align-items: center;
    padding: 1.5rem .75rem;
  }

  .signature-type-description {
    flex-basis: 70%;
    flex-grow: 1;
    padding-right: 1rem;

    .title {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: .25rem;
    }

    .desc {
      font-size: .85rem;
      margin: 0;
    }
  }
}

.contract-finalization-modal {
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 80%;
    }
  }
}

.cadastral-info {
  font-size: .85rem;

  p {
    margin-bottom: .25rem;
  }
}

.react-autocomplete-input {
  max-height: 350px;
  overflow: auto;
}

//change textarea color for the field reference
textarea.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ddd;
}
textarea.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #ddd;
}
textarea.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #ddd;
}
textarea.form-control:-moz-placeholder { /* Firefox 18- */
  color: #ddd;
}

input.phone-number::-webkit-outer-spin-button,
input.phone-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.phone-number[type=number] {
  -moz-appearance: textfield;
}

.user-score {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .score-label {
    font-size: .8rem;
    color: #6c757d;
    font-weight: bold;
  }
}

.score-circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid $white;
  margin-right: .25rem;

  &.bad {
    background-color: $red;
  }

  &.good {
    background-color: $green;
  }

  &.ongoing {
    background-color: $abYellow;
  }

  &.unknown {
    background-color: $grey;
  }
}

.input-loading-spinner {
  margin-top: -.25rem;
  display: block;
  margin-bottom: .75rem;
}