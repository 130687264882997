.table-container {
  position: relative;
  z-index: 1;
  margin-bottom: 2.5rem;

  &.loading {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      background-color: rgba(250, 250, 250, .7);
    }
  }
}

.table {
  margin: 0;

  thead th {
    font-weight: 600;
    text-transform: uppercase;
    font-size: .7rem;
    color: $abBlue;
    border-bottom-color: $abBlue;
    border-top: none;
    padding: .75rem .5rem;

    &.sortable-column {
      cursor: pointer;
    }

    &.active-col {
      background-color: rgba(51, 122, 183, 0.7);
      color: $white;
    }
  }

  tbody tr {
    &.is-deleting {
      opacity: .3;
    }
  }

  tbody td {
    vertical-align: middle;
    font-size: .75rem;
    font-weight: 600;
    color: $tableContent;
    padding: .75rem .35rem;

    &.break-word {
      word-break: break-all;
    }

    &.score-circle-container {
      text-align: center;
    }

    .communication-actions-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fa-envelope,
    .fa-comments {
      color: $grey;

      &.active {
        color: $abBlue;
      }
    }

    &.status {
      color: $abBlue;

      .fa-circle-xmark {
        color: red;
      }
      .fa-door-open {
        color: $doorOpen;
      }
      .fa-signature {
        color: $signature;
      }
      .fa-gears {
        color: $cogs;
      }
      .fa-circle-check {
        color: $checkCirlce;
      }
      .fa-hand {
        color: $handPaper;
      }
      .fa-circle-half-stroke {
        color: $abYellow;
      }
    }

    &.actions-col {
      .buttons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .btn {
        margin: .25rem .15rem !important;
      }
    }

    &.active-col {
      background-color: rgba(51, 122, 183, 0.7);
      color: $white;
    }
  }
}

.excel-download-container {
  display: flex;
  justify-content: flex-end;
  margin: .5rem 0;

  > button {
    margin-left: .5rem;
  }
}

.status-circ {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  border: none;
  overflow: hidden;
  margin: auto;

  &.green {
    background-color: $checkCirlce;
  }

  &.red {
    background-color: $red;
  }

  &.yellow {
    background-color: $abYellow;
  }
}