.list-group-item.cadastral {
  padding: 0;
  margin-bottom: 2.5rem;
  border-top: 1px solid $grey;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.cadastral-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .title {
    margin: 0;
  }

  .buttons-container {
    display: flex;
    
    button {
      margin-right: .5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.cadastral-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem;
}

.sub-items {
  background-color: $white;
  padding: .75rem;
  border-top: 1px solid $grey;

  .title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: .5rem;
  }

  .card-title {
    .fa-circle-xmark {
      color: red;
    }
    .fa-door-open {
      color: $doorOpen;
    }
    .fa-signature {
      color: $signature;
    }
    .fa-gears {
      color: $cogs;
    }
    .fa-clipboard-check {
      color: $clipBoardCheck;
    }
    .fa-circle-check {
      color: $checkCirlce;
    }
    .fa-hand {
      color: $handPaper;
    }
    .fa-circle-half-stroke {
      color: $abYellow;
    }
    .fa-arrows-rotate {
      color: $abBlue;
    }
  }

  .card-body {
    button {
      margin-bottom: .5rem;
      font-size: .85rem;
    }
  }
}

.active-user-supply-status-icon {
  &.fa-circle-xmark {
    color: red;
  }
  &.fa-door-open {
    color: $doorOpen;
  }
  &.fa-signature {
    color: $signature;
  }
  &.fa-gears {
    color: $cogs;
  }
  &.fa-clipboard-check {
    color: $clipBoardCheck;
  }
  &.fa-circle-check {
    color: $checkCirlce;
  }
  &.fa-hand {
    color: $handPaper;
  }
  &.fa-circle-half-stroke {
    color: $abYellow;
  }
  &.fa-arrows-rotate {
    color: $abBlue;
  }
}