.otp-container {
  margin: 1rem 0;

  .grouped-header {
    &.signed {
      background-color: rgba(39, 196, 39, .18);

      .fa-check-circle {
        color: #4fab4f;
      }
    }
  }
}
