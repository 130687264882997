.btn {
  font-weight: 700;
  font-size: .85rem;
}

.ab-button {
  background-color: $abBlue;
  color: $white;
}

.link-button {
  padding: 0;
  text-decoration: underline;
  border: 0;
}

.btn-link {
  color: $abBlue;
  font-weight: 600;
  font-size: inherit;
  vertical-align: baseline;
}

.btn-label {
  margin-left: .5rem;
}
