.loader-container {
  margin: 2.5rem auto;
}

.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  background-color: rgba(250, 250, 250, .8);
  z-index: 15;

  > .label {
    margin-top: 1rem;
    font-size: .85rem;
    color: $abBlue;
    font-weight: 600;
  }

  .spinner-border {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid rgba(195, 195, 195, 0.6);
    border-top-color: #337ab7;

    animation: spin 1s ease-in-out infinite;

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}

.element-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}
