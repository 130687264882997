.pagination {
  margin-bottom: 0;
  flex-wrap: wrap;
}

.page-item {
  .page-link {
    color: $abBlue;
    font-size: .75rem;
  }

  &.active {
    .page-link {
      background-color: $abBlue;
    }
  }
}

.total-items {
  font-size: .75rem;
  margin-bottom: 0;
  color: $statusColor;
  font-weight: 600;
  text-align: right;

  strong {
    color: $abBlue;
    margin-right: .15rem;
  }
}
